(function ($) {
  'use strict';

  function onTimerComplete(clockObj) {
    var textComplete = '';
    var textCompleteElement = clockObj.querySelector('.js-countdown-timer__text-complete');
    var textC;

    if (typeof textCompleteElement !== 'undefined' && textCompleteElement) {
      textComplete = textCompleteElement.innerHTML;
    }

    if (textComplete.length) {
      textC = $(clockObj).find('.js-countdown-timer__text-complete');

      if (textC) {
        // Hide the clock and show the complete text
        textC.innerHTML = textComplete;
        $(clockObj).find('.js-countdown-timer__header').addClass('hidden');
        $(clockObj).find('.js-countdown-timer__watch').addClass('countdown-timer__watch-hidden');
        textC.removeClass('hidden');
      }
    }
  }

  function zeroPad(num, places) {
    return String(num).padStart(places, '0');
  }

  function getCountdownTimeUnits(countdown) {
    return {
      days: Math.floor(countdown / (1000 * 60 * 60 * 24)),
      hours: Math.floor((countdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((countdown % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((countdown % (1000 * 60)) / 1000)
    };
  }

  function getFormattedTimeUnits(daysLeft, hoursLeft, minutesLeft, secondsLeft) {
    return {
      days: zeroPad(daysLeft, 2),
      hours: zeroPad(hoursLeft, 2),
      minutes: zeroPad(minutesLeft, 2),
      seconds: zeroPad(secondsLeft, 2)
    };
  }

  function updateTimeUnitDOM(domElement, value, fadeOut) {
    const timeUnitDOM = domElement;

    if (domElement) {
      timeUnitDOM.innerHTML = value;
      if (fadeOut) {
        timeUnitDOM.parentElement.classList.add('countdown-timer__watch-box-opacity');
      }
    }
  }

  function initializeClock(clockElm, endTiming) {
    var clock = clockElm;
    var daysElem;
    var hoursElem;
    var minutesElem;
    var secondsElem;
    var timeInterval;

    if (typeof clock === 'undefined' || !clock) {
      return;
    }

    daysElem = clock.querySelector('.js-countdown-timer__watch-days');
    hoursElem = clock.querySelector('.js-countdown-timer__watch-hours');
    minutesElem = clock.querySelector('.js-countdown-timer__watch-minutes');
    secondsElem = clock.querySelector('.js-countdown-timer__watch-seconds');

    function updateClock() {
      const now = new Date().getTime();
      const currentCountdown = endTiming - now;
      const hasExpired = currentCountdown <= 0;

      if (hasExpired) {
        clearInterval(timeInterval);
        onTimerComplete(clock);

        return;
      }

      const timeLeft = getCountdownTimeUnits(currentCountdown);
      const formattedTimeLeftUnits = getFormattedTimeUnits(
        timeLeft.days,
        timeLeft.hours,
        timeLeft.minutes,
        timeLeft.seconds
      );
      const noDaysLeft = timeLeft.days === 0;
      const noHoursLeft = timeLeft.hours === 0 && noDaysLeft;
      const noMinutesLeft = timeLeft.minutes === 0 && noHoursLeft;
      const noSecondsLeft = timeLeft.seconds === 0 && noMinutesLeft;

      updateTimeUnitDOM(daysElem, formattedTimeLeftUnits.days, noDaysLeft);
      updateTimeUnitDOM(hoursElem, formattedTimeLeftUnits.hours, noHoursLeft);
      updateTimeUnitDOM(minutesElem, formattedTimeLeftUnits.minutes, noMinutesLeft);
      updateTimeUnitDOM(secondsElem, formattedTimeLeftUnits.seconds, noSecondsLeft);
    }

    updateClock();
    timeInterval = setInterval(updateClock, 1000);
  }

  Drupal.behaviors.CountdownTimerV1 = {
    init: function (context) {
      $('.js-countdown-timer-v1', context).each(function (objI, objElm) {
        var fullDateElement = objElm.querySelector('.js-countdown-timer__full-date');
        var fullDate;
        var endtime;

        if (typeof fullDateElement !== 'undefined' && fullDateElement) {
          fullDate = fullDateElement.innerHTML.replace(/&nbsp;/gu, '');
          endtime = new Date(fullDate).getTime();

          initializeClock(objElm, endtime);
        }
      });
    },
    attach: function (context) {
      this.init(context);
    }
  };
})(jQuery);
